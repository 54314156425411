/* eslint-disable */
export default {
    /**
     * Content Selector
     */

    "contentSelector.campaignSelection": "Kampagne auswählen",

    /**
     * Recipient
     */
    recipienst: "Empfänger",
    "recipients.import": "Empfänger importieren",
    "recipients.create": "Neuen Empfänger erstellen",
    "recipients.edit": "Empfänger bearbeiten",
    "recipients.delete": "Empfänger löschen",
    "recipients.deleteAll": "Alle Empfänger löschen",
    "recipients.edited": "Empfänger wurde erfolgreich bearbeitet",
    "recipients.deleted": "Empfänger wurde erfolgreich gelöscht",
    "recipients.created": "Empfänger wurde erfolgreich erstellt",
    recipientList: "Recipientliste",

    itemsPerPage: "Ergebnisse pro Seite",
};
