import {
    IMPORT_CONTENT_SELECTOR,
    FETCH_CONTENT_SELECTOR_CONFIGS,
    FETCH_CONTENT_SELECTOR_CONFIG,
    DELETE_CONTENT_SELECTOR_CONFIGS,
    DELETE_CONTENT_SELECTOR_CONFIG,
    DELETE_ALL_CONTENT_SELECTOR_CONFIGS,
    CLEAN_CONTENT_CONNECTOR,
    FETCH_CONTENT_SELECTOR_TYPES,
    LOADER_CONTENT_SELECTOR_CONFIGS_LIST,
    LOADER_CONTENT_SELECTOR_CONFIGS_IMPORT,
} from "../actions/types";

const initialState = {
    requestStatus: {},
    importedContentSelector: {},
    contentSelectorConfigs: {},
    contentSelectorTypes: {},
    contentSelectorConfig: {},
    deletedContentSelectorConfigs: [],
    deletedContentSelectorConfig: {},
    deletedAllContentSelectorConfigs: null,
    loaderContentSelectorConfigList: false,
    loaderContentSelectorConfigImport: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case IMPORT_CONTENT_SELECTOR:
            return {
                ...state,
                importedContentSelector: action.payload,
                loaderContentSelectorConfigImport: false,
            };
        case CLEAN_CONTENT_CONNECTOR:
            return {
                ...state,
                importedContentSelector: {},
            };
        case FETCH_CONTENT_SELECTOR_CONFIGS:
            return {
                ...state,
                contentSelectorConfigs: action.payload,
                loaderContentSelectorConfigList: false,
            };
        case FETCH_CONTENT_SELECTOR_CONFIG:
            return {
                ...state,
                contentSelectorConfig: action.payload,
            };
        case DELETE_CONTENT_SELECTOR_CONFIG:
            return {
                ...state,
                deletedContentSelectorConfig: action.payload,
                loaderContentSelectorConfigList: false,
            };
        case DELETE_CONTENT_SELECTOR_CONFIGS:
            return {
                ...state,
                deletedContentSelectorConfigs: action.payload,
                loaderContentSelectorConfigList: false,
            };

        case DELETE_ALL_CONTENT_SELECTOR_CONFIGS:
            return {
                ...state,
                deletedAllContentSelectorConfigs: action.payload,
                loaderContentSelectorConfigList: false,
            };
        case FETCH_CONTENT_SELECTOR_TYPES:
            return {
                ...state,
                contentSelectorTypes: action.payload,
            };
        case LOADER_CONTENT_SELECTOR_CONFIGS_LIST:
            return {
                ...state,
                loaderContentSelectorConfigList: true,
            };
        case LOADER_CONTENT_SELECTOR_CONFIGS_IMPORT:
            return {
                ...state,
                loaderContentSelectorConfigImport: true,
            };
        default:
            return state;
    }
}
