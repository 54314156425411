import {
    FETCH_CAMPAIGNS
} from "../actions/types";
const initialState = {
    campaigns: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload,
            };
        default:
            return state;
    }
}