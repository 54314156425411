/* eslint-disable */
import React from "react";
import { FormattedMessage } from "react-intl";
import core_routes from "./core/core_routes";
import _ from "lodash";

const Recipients = React.lazy(() =>
    import("./crm/components/Recipients/Recipients")
);
const RecipientForm = React.lazy(() =>
    import("./crm/components/Recipients/RecipientForm")
);
const RecpientImport = React.lazy(() =>
    import("./crm/components/Recipients/RecipientImport")
);
const Recommendations = React.lazy(() =>
    import("./crm/components/ContentSelectors/ContentSelectors")
);
const RecommendationsImport = React.lazy(() =>
    import("./crm/components/ContentSelectors/ContentSelectorsImport")
);

const Connector = React.lazy(() =>
    import("./crm/components/Connector/Connector")
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    {
        path: "/",
        exact: true,
        name: <FormattedMessage id="home" defaultMessage="Home" />,
    },
    {
        path: "/recipients",
        exact: true,
        name: <FormattedMessage id="recipient" defaultMessage="Recipients" />,
        component: Recipients,
    },
    {
        path: "/recipients/create",
        exact: true,
        name: (
            <FormattedMessage
                id="recipient.create"
                defaultMessage="Create Recipient"
            />
        ),
        component: RecipientForm,
    },
    {
        path: "/recipients/edit/:recipientId",
        exact: true,
        name: (
            <FormattedMessage
                id="recipient.edit"
                defaultMessage="Edit Recipient"
            />
        ),
        component: RecipientForm,
    },
    {
        path: "/recipients/import",
        exact: true,
        name: <FormattedMessage id="import" defaultMessage="Import" />,
        component: RecpientImport,
    },
    {
        path: "/connector",
        exact: true,
        name: <FormattedMessage id="content" defaultMessage="Contents" />,
        component: Connector,
    },
    // { path: '/recommendations', exact: true, name: <FormattedMessage id="contentSelector" defaultMessage="Recommendations" />, component: Recommendations },
    // { path: '/recommendations/import', exact: true, name: <FormattedMessage id="import" defaultMessage="Import" />, component: RecommendationsImport }
];

const combinedRoutes = _.concat(core_routes, routes);
export default combinedRoutes;
