export const FETCH_RECIPIENTS = "FETCH_RECIPIENTS";
export const FETCH_RECIPIENT = "FETCH_RECIPIENT";
export const CREATE_RECIPIENT = "CREATE_RECIPIENT";
export const DELETE_RECIPIENT = "DELETE_RECIPIENT";
export const DELETE_RECIPIENTS = "DELETE_RECIPIENTS";
export const DELETE_ALL_RECIPIENTS = "DELETE_ALL_RECIPIENTS";
export const UPDATE_RECIPIENT = "UPDATE_RECIPIENT";
export const CLEAN_IMPORTED_RECIPIENTS = "CLEAN_IMPORTED_RECIPIENTS";
export const REMOVE_DELETED_RECIPIENT = "REMOVE_DELETED_RECIPIENT";
export const SET_IMPORTED_RECIPIENT_FILE = "SET_IMPORTED_RECIPIENT_FILE";
export const REMOVE_IMPORTED_RECIPIENT_FILE = "REMOVE_IMPORTED_RECIPIENT_FILE";
export const UPLOAD_RECIPIENTS = "UPLOAD_RECIPIENTS";
export const LOADER_RECIPIENTS_LIST = "LOADER_RECIPIENTS_LIST";
export const LOADER_RECIPIENTS_IMPORT = "LOADER_RECIPIENTS_IMPORT";
export const LOADER_RECIPIENTS_FORM = "LOADER_RECIPIENTS_FORM";

export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";

export const IMPORT_CONTENT_SELECTOR = "IMPORT_CONTENT_SELECTOR";
export const SET_IMPORTED_CONTENT_SELECTOR_FILE =
    "SET_IMPORTED_CONTENT_SELECTOR_FILE";
export const REMOVE_IMPORTED_CONTENT_SELECTOR_FILE =
    "REMOVE_IMPORTED_CONTENT_SELECTOR_FILE";
export const CLEAN_CONTENT_CONNECTOR = "CLEAN_CONTENT_CONNECTOR";
export const FETCH_CONTENT_SELECTOR_CONFIGS = "FETCH_CONTENT_SELECTOR_CONFIGS";
export const FETCH_CONTENT_SELECTOR_TYPES = "FETCH_CONTENT_SELECTOR_TYPES";
export const FETCH_CONTENT_SELECTOR_CONFIG = "FETCH_CONTENT_SELECTOR_CONFIG";
export const DELETE_CONTENT_SELECTOR_CONFIG = "DELETE_CONTENT_SELECTOR_CONFIG";
export const DELETE_CONTENT_SELECTOR_CONFIGS =
    "DELETE_CONTENT_SELECTOR_CONFIGS";
export const DELETE_ALL_CONTENT_SELECTOR_CONFIGS =
    "DELETE_ALL_CONTENT_SELECTOR_CONFIGS";
export const LOADER_CONTENT_SELECTOR_CONFIGS_LIST =
    "LOADER_CONTENT_SELECTOR_CONFIGS_LIST";
export const LOADER_CONTENT_SELECTOR_CONFIGS_IMPORT =
    "LOADER_CONTENT_SELECTOR_CONFIGS_IMPORT";

export const CONNECTOR_HANDSHAKE = "CONNECTOR_HANDSHAKE";
