import React from "react";

import { useFormatMessage } from "react-intl-hooks";

import routes from "./routes";

const TheLayout = React.lazy(() => import("./core/core_containers/TheLayout"));

function Layout(props) {
    const mes = useFormatMessage();

    const nav = [
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "recipients", defaultMessage: "Recipients" }),
            to: "/recipients",
            icon: "cil-layers",
        },
        // {
        //     _tag: 'CSidebarNavItem',
        //     name: mes({ id: "contentSelector", defaultMessage: "Recommendations" }),
        //     to: '/recommendations',
        //     icon: 'cil-life-ring',
        // },     
        {
            _tag: "CSidebarNavTitle",
            name: "adminstration",
            _children: ["administration"],
        },   
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "workspaces", defaultMessage: "Workspaces" }),
            to: "/workspaces",
            icon: "cil-user",
        },
        {
            _tag: "CSidebarNavItem",
            name: mes({ id: "users", defaultMessage: "Users" }),
            to: "/users",
            icon: "cil-people",
        },
    ];

    return (
        <TheLayout
            toaster={props.toaster}
            routes={routes}
            nav={nav}
            {...props}
        />
    );
}

export default Layout;
