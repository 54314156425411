/* eslint-disable */
export default {
    /**
     * Content Selector
     */

    "contentSelector.campaignSelection": "Select Campaign",

    /**
     * Recipient
     */
    recipients: "Recipients",
    "recipients.create": "Create a new recipient",
    "recipients.import": "Import recipients from an Excel file",
    "recipients.edit": "Edit recipient",
    "recipients.delete": "Delete recipient",
    "recipients.deleteAll": "Delete all recipients",
    "recipients.edited": "Recipient edited Successfully",
    "recipients.deleted": "Recipient deleted Successfully",
    "recipients.created": "Recipient created Successfully",
    recipientList: "Recipient List",

    itemsPerPage: "Items per page",
};
