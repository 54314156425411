import { CONNECTOR_HANDSHAKE } from "../actions/types";

const initialState = {
    handshake: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONNECTOR_HANDSHAKE:
            return {
                ...state,
                handshake: action.payload,
            };
        default:
            return state;
    }
}
