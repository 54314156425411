import { combineReducers } from "redux";
import recipientReducer from "./recipientReducer";
import contentSelectorReducer from "./contentSelectorReducer";
import core_reducer from "../../core/core_reducer/index";
import campaignReducer from "./campaignReducer";
import connectorReducer from "./connectorReducer";

export default combineReducers({
    campaigns: campaignReducer,
    recipients: recipientReducer,
    contentSelector: contentSelectorReducer,
    connector: connectorReducer,
    ...core_reducer,
});
