import {
    FETCH_RECIPIENTS,
    FETCH_RECIPIENT,
    DELETE_RECIPIENT,
    DELETE_RECIPIENTS,
    DELETE_ALL_RECIPIENTS,
    REMOVE_DELETED_RECIPIENT,
    CREATE_RECIPIENT,
    UPDATE_RECIPIENT,
    SET_IMPORTED_RECIPIENT_FILE,
    REMOVE_IMPORTED_RECIPIENT_FILE,
    UPLOAD_RECIPIENTS,
    CLEAN_IMPORTED_RECIPIENTS,
    LOADER_RECIPIENTS_FORM,
    LOADER_RECIPIENTS_IMPORT,
    LOADER_RECIPIENTS_LIST,
} from "../actions/types";

const initialState = {
    uploadedRecipients: {},
    createdRecipient: {},
    deletedRecipient: -1,
    deletedRecipients: -1,
    deleteAllRecipients: null,
    updatedRecipient: {},
    recipients: {},
    recipient: {},
    importedRecipients: null,
    loaderRecipientsList: false,
    loaderRecipientsForm: false,
    loaderRecipientsImport: false,
};
export default function localeReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_RECIPIENT:
            return {
                ...state,
                createdRecipient: action.payload,
                loaderRecipientsForm: false,
            };
        case UPLOAD_RECIPIENTS:
            return {
                ...state,
                uploadedRecipients: action.payload,
                loaderRecipientsImport: false,
            };
        case UPDATE_RECIPIENT:
            return {
                ...state,
                updatedRecipient: action.payload,
                loaderRecipientsForm: false,
            };
        case FETCH_RECIPIENT:
            return {
                ...state,
                recipient: action.payload,
                loaderRecipientsForm: false,
            };
        case FETCH_RECIPIENTS:
            return {
                ...state,
                recipients: action.payload,
                loaderRecipientsList: false,
            };
        case DELETE_RECIPIENT:
            return {
                ...state,
                deleteRecipient: action.payload,
                loaderRecipientsList: false,
            };
        case DELETE_RECIPIENTS:
            return {
                ...state,
                deleteRecipients: action.payload,
                loaderRecipientsList: false,
            };
        case DELETE_ALL_RECIPIENTS:
            return {
                ...state,
                deleteAllRecipients: action.payload,
                loaderRecipientsList: false,
            };
        case REMOVE_DELETED_RECIPIENT:
            return {
                ...state,
                deleteRecipient: -1,
            };
        case SET_IMPORTED_RECIPIENT_FILE:
            return {
                ...state,
                importedRecipients: action.payload,
            };
        case REMOVE_IMPORTED_RECIPIENT_FILE:
            return {
                ...state,
                importedRecipients: null,
            };
        case CLEAN_IMPORTED_RECIPIENTS:
            return {
                ...state,
                recipients: [],
            };
        case LOADER_RECIPIENTS_FORM:
            return {
                ...state,
                loaderRecipientsForm: true,
            };
        case LOADER_RECIPIENTS_LIST:
            return {
                ...state,
                loaderRecipientsList: true,
            };
        case LOADER_RECIPIENTS_IMPORT:
            return {
                ...state,
                loaderRecipientsImport: true,
            };
        default:
            return state;
    }
}
